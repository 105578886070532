<template>
  <section class="Add_Post_Components">
    <!-- created Post Modal -->
    <b-modal id="modal1" centered :title="$t('Posts.15b@create_post')" hide-footer @hidden="hideModalFunc"
      v-if="hasPost">
      <!--  -->
      <div class="d-flex content_post">
        <div class="user-img">
          <img v-if="userInfo.avatar" style="object-fit: cover" class="avatar-60 rounded-circle"
            :src="userInfo.avatar" />
        </div>

        <section style="flex:1">
          <section class="post_remark_content">
            <div class="detail_content" id="detail_content">
              <p v-html="remarkContent" id="p-content"></p>
            </div>
            <div class="showLave">{{ addPostObj.content.length }} / 1000</div>
            <div class="postTextarea">
              <textarea maxlength="1000" v-model="addPostObj.content" id="textarea_content"
                :placeholder="$t('Posts.460@write_something_here')" style="
                  width: calc(100% - 15px);
                  height: 100%;
                  border: none;
                  resize: none;
                "></textarea>
              <!-- <div class="showLave">{{ addPostObj.content.length }} / 1000</div> -->
            </div>
          </section>

          <!-- location -->
          <div class="mb-3 d-flex" v-if="addPostObj.location">
            <section class="location_box">
              <i class="ri-map-pin-line"></i>
              <span>{{ addPostObj.location }}</span>
              <i class="el-icon-close" @click="addPostObj.location=''"></i>
            </section>
          </div>

          <!-- friend -->
          <ul class="modal_friend_list p-0">
            <li v-for="(item, index) in friendsInfoList" class="friend_box" :key="index">
              <span class="text-primary">@{{ item.nick_name }}</span>
              <i class="el-icon-close" @click="delFriend(index)"></i>
            </li>
          </ul>

          <!-- 拖拽上传图片 -->
          <div class="drag_container" v-loading="loadingImg3">
            <el-upload class="upload-demo" drag action="no" :http-request="params => {imgUpload(params, 3)}"
              :show-file-list="false" multiple>
              <div class="all_image d-flex flex-wrap" v-if="addPostObj.media.length">
                <div class="common_item" :class="imgClass" v-for="(v, i) in addPostObj.media" :key="i">
                  <img v-if="v.type == 'photo'" :src="v.url" alt="" class="main_img" />
                  <video v-else :src="v.url" controls autoplay></video>
                  <img class="close_img" src="@/assets/images/add_post_close.png" @click.stop="unshiftMedia(i)" />
                </div>
              </div>
              <div class="drag_content d-flex flex-column align-items-center justify-content-center" v-else>
                <img src="@/assets/images/icon/drag_icon.png" alt="">
                <div class="el-upload__text mt-3">Add Pictures / Video Drag & Drop</div>
              </div>
            </el-upload>
          </div>


        </section>
      </div>

      <div style="padding: 0 30px;">
        <hr />
      </div>

      <!-- share -->
      <div v-if="shareLink" class="share_link mb-2" style="margin:0 30px">
        <div style="width: 80px" class="img_box" v-if="
            sharePostInfomation.media && sharePostInfomation.media.length > 0
          ">
          <img style="object-fit: cover" v-if="sharePostInfomation.media[0].type == 'photo'"
            :src="sharePostInfomation.media[0].url" alt="" />
          <video v-else :src="sharePostInfomation.media[0].url"></video>
        </div>
        <div style="width: 80px" class="img_box" v-if="sharePostInfomation.image">
          <img style="object-fit: cover" :src="sharePostInfomation.image" alt="" />
        </div>
        <div class="share_content">
          <span v-if="sharePostInfomation.nick_name">@{{ sharePostInfomation.nick_name }}</span>
          <span v-if="sharePostInfomation.title">{{
            sharePostInfomation.title
            }}</span>
          <p class="m-0" v-html="postContentFilter(sharePostInfomation.content)"></p>
        </div>
      </div>

      <!-- tag -->
      <section class="d-flex align-items-center list-inline mt-2 justify-content-between option_container">
        <div class="d-flex align-items-center">
          <div>
            <div class="iq-bg-primary rounded p-2 pointer mr-2">
              <el-upload class="avatar-uploader" action="no" :http-request="params => {imgUpload(params, 1)}"
                :show-file-list="false" v-loading="loadingImg1">
                <img src="@/assets/images/small/07.png" class="img-fluid" />
                {{ $t("Posts.83d@photo_video") }}
              </el-upload>
            </div>
          </div>
          <div class="mr-2">
            <b-dropdown style="width: 100%">
              <template #button-content>
                <div class="iq-bg-primary rounded text-left p-2 pointer">
                  <img src="@/assets/images/small/08.png" class="img-fluid" />
                  {{ $t("Posts.80b@tag_friend") }}
                </div>
              </template>
              <div class="pl-2 pr-2">
                <b-input :placeholder="$t('Posts.7ec@search_or_select_fri')" v-model="searchFriend"></b-input>
              </div>
              <b-dropdown-item @click="chooseOneFriend(item)" v-for="(item, index) in Newitems" :key="index">
                {{ item.nick_name }}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div>
            <b-dropdown style="width: 100%">
              <template #button-content>
                <div @click="googleNearbyFunc" class="iq-bg-primary rounded text-left p-2 pointer mr-3">
                  <img src="@/assets/images/small/10.png" class="img-fluid" />
                  {{ $t("Posts.5f6@check_in") }}
                </div>
              </template>

              <div v-if="addressLoading == 'error'" @click="googleNearbyFunc"
                class="d-flex justify-content-center align-content-xl-start">
                <i class="ri-refresh-line"></i>{{ $t("Posts.3f9@reload") }}
              </div>
              <div class="m-3 p-3" v-if="addressLoading == 'loading'">
                <div v-loading="true"></div>
              </div>

              <div v-infinite-scroll="googleNearbyFunc" infinite-scroll-disabled="addressDis"
                infinite-scroll-delay="500" v-if="addressLoading == 'success'">
                <b-dropdown-item @click="chooseAddress(item)" v-for="(item, index) in AddressList" :key="index">
                  {{ item.name }}</b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
        </div>

        <!-- permission -->
        <div class="other-option">
          <el-dropdown @command="handlePermissionCommand" trigger="click">
            <span class="el-dropdown-link">
              {{ permission | permissionFilter}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="1">{{ $t("Posts.c0c@public") }}</el-dropdown-item>
              <el-dropdown-item :command="0">{{$t("Posts.9df@friends")}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </section>

      <button @click="addNewPost" class="btn btn-primary d-block w-100 mt-3 add_new_post">
        {{ $t("Posts.a63@post") }}
      </button>
    </b-modal>
  </section>
</template>

<script>
  import bus from "@/Utils/Bus";
  import { mapGetters } from "vuex";

  import { InfiniteScroll } from "element-ui";
  export default {
    name: "SharePost",
    inject: ["reload"],
    directives: {
      "infinite-scroll": InfiniteScroll,
    },
    data() {
      return {
        avatar: "",

        // 朋友可见||全部可见
        permission: 1,

        // 地址列表loading
        addressLoading: false,
        addressDis: false,

        // 发帖项
        addPostObj: {
          content: "",
          media: [],
          friends: [],
          location: "",
          longitude: "",
          latitude: "",
          share_type: null,
          share_id: null,
        },

        // 页面上遍历@friends
        friendsInfoList: [],

        // 页面上地址列表
        AddressList: [],
        nextAddressToken: "",

        // 分享
        shareLink: false,
        sharePostInfomation: {},
        shareId: 0,

        searchFriend: "",

        // 判断是否是误触关闭发帖弹框
        isPostClose: false,
        loadingImg: false,
        loadingImg1: false,
        loadingImg3: false,
        friendList: [],
        hasPost: "",
      };
    },

    filters: {
      permissionFilter(val) {
        return val == 1 ? "Public" : "Friends";
      },
    },

    computed: {
      // 判断样式
      imgClass() {
        if (this.addPostObj.media.length == 1) return 'one_img'
        if (this.addPostObj.media.length == 4) return 'four_img'
        return 'image_item'
      },
      ...mapGetters({
        userInfo: "Public/getUserInfo",
      }),
      isPostBtnDis() {
        // console.log('dis', this.addPostObj.media.length || this.addPostObj.friends.length || this.addPostObj.location);
        return (
          this.addPostObj.media.length ||
          this.addPostObj.friends.length ||
          this.addPostObj.location
        );
      },
      Newitems() {
        let friendList = [];
        this.friendList.map((item) => {
          if (!item.nick_name) return;
          if (
            item.nick_name.toUpperCase().includes(this.searchFriend.toUpperCase())
          ) {
            friendList.push(item);
          }
        });
        return friendList;
      },

      remarkContent() {
        let val = this.addPostObj.content;
        let reg = /(^|\s+)#([^!@#$%^&*()=+./,:'"?<>(^|\s+)]+)/g;
        val = val.replace(reg, `<a>$1#$2</a>`).replace(/\s{2}/g, "&nbsp;&nbsp;");
        val = val.replace(/(\r\n|\n|\r)/gm, "<br />").replace(/\s{2}/g, "&nbsp;&nbsp;");
        return val;
      },

      // 判斷表单有没有填写
    },

    created() { },

    mounted() {
      this.getUserInfo();
    },

    methods: {
      // 删除好友
      delFriend(index) {
        this.friendsInfoList.splice(index, 1);
        this.addPostObj.friends.splice(index, 1);
      },

      _getPost(e) {
        // console.log("xwy");
        this.hasPost = e;
        this.$bvModal.show("modal1");
        this.shareLink = true;
        // this.getShareInfo(e.id);
        this.shareId = e.id || e.post_id;
        this.sharePostInfomation = e;
        this.scrollEvent()
      },


      // 文本域滚动条事件
      scrollEvent() {
        setTimeout(() => {
          document.getElementById("textarea_content").addEventListener("scroll", handleScroll);
        }, 0);
        function handleScroll() {
          //获取dom滚动距离
          let textareaTop = document.getElementById("textarea_content");
          let contentTop = document.getElementById('detail_content');
          let p_content = document.getElementById('p-content');
          p_content.style.height = textareaTop.scrollHeight + 'px'
          contentTop.scrollTop = textareaTop.scrollTop;
        }
      },

      // 好友列表
      userFriendList() {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.$http
          .userFriendList({
            user_id: userInfo.user_id,
            type: 4,
          })
          .then((res) => {
            let list = res.data;
            this.friendsList = list.length > 9 ? list.splice(0, 9) : list;
          });
      },

      // 删除好友
      delFriend(index) {
        this.friendsInfoList.splice(index, 1);
        this.addPostObj.friends.splice(index, 1);
      },
      // qingkong表单
      clearForm() {
        this.addPostObj.id = "";
        this.addPostObj.content = "";
        this.addPostObj.friends = [];
        this.addPostObj.media = [];
        this.addPostObj.share_type = null;
        this.friendsInfoList = [];
        // this.AddressList = [];
        this.addPostObj.location = "";
        this.searchFriend = "";
      },

      // 页面上帖子权限得选择
      handlePermissionCommand(val) {
        this.permission = val;
      },

      // 监听modal关闭
      hideModalFunc(bvModalEvt, modalId) {
        this.hasPost = "";
        // console.log('bvModalEvt', bvModalEvt);
        if (this.isPostClose) {
          // 是正常的发帖关闭
          this.clearForm();
        } else {
          if (
            !this.addPostObj.content &&
            !this.addPostObj.friends.length &&
            !this.addPostObj.media.length &&
            !this.addPostObj.share_type &&
            !this.friendsInfoList.length &&
            // this.AddressList = [];
            !this.addPostObj.location &&
            !this.searchFriend
          ) {
            return this.clearForm();
          }
          this.$confirm(this.$t("Posts.488@close_the_pop_up_box"), "Tips", {
            distinguishCancelAndClose: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          })
            .then(() => {
              this.clearForm();
            })
            .catch(() => {
              this.$bvModal.show("modal1");
            });
        }
      },
      // 帖子内容过滤
      postContentFilter(val) {
        if (!val) return;
        let exp =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        val = val.replace(
          exp,
          "<a href='$1' target='_blank'><i class='ri-link-m'></i>Link</a>"
        );
        let url = window.location.href.split('#')[0]
        let reg = /(^|\s+)#([^!@#$%^&*()=+./,:'"?<>(^|\s+)]+)/g
        val = val.replace(reg, `<a href='${url}#/search-result?keyword=$2'>$1#$2</a>`)
        val = val.replace(/(\r\n|\n|\r)/gm, "<br />")
        return val
      },
      // 上传
      async imgUpload(params, type) {
        if (this.addPostObj.media.length >= 9) return this.$message.warning('No more than 9')
        if (type == 1) {
          this.loadingImg1 = true;
        } else {
          this.loadingImg3 = true
        }
        const isJPG = params.file.type.split("/")[0] === "image";
        const isLt5M = params.file.size / 1024 / 1024 < 5;
        // console.log("params.file.size / 1024 / 1024", params.file.size / 1024 / 1024);
        this.isLt5MYS = true;
        var formData = new FormData();
        if (isJPG && !isLt5M) {
          await this._reader(params.file);
          let newFile = new window.File([this.newFile], params.file.name, {
            type: params.file.type,
          });
          formData.append("file", newFile);
        } else {
          formData.append("file", params.file);
        }
        if (!this.isLt5MYS) return;
        this.$http.uploadImage(formData).then((res) => {
          this.loadingImg1 = false;
          this.loadingImg3 = false
          if (res.status == 200 && this.addPostObj.media.length < 9) {
            this.addPostObj.media.push({
              type: params.file.type.split("/")[0] == "image" ? "photo" : "video",
              url: res.data[0],
            });
          }
          // console.log(this.addPostObj.media);
          // console.log(this.addPostObj);
        });
      },

      // async beforeAvatarUpload(file) {
      //   const isJPG = file.type.split("/")[0] === "image";
      //   const isLt5M = file.size / 1024 / 1024 < 5;
      //   if (isJPG && !isLt5M) {
      //     await this._reader(file);
      //     return this.isLt5MYS;
      //   } else {
      //     return isLt5M;
      //   }
      // },

      _reader(file) {
        return new Promise(async (resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async () => {
            await this.canvasDataURL(
              {
                base64: reader.result,
                name: file.name,
              },
              file.type
            );
            // console.log("this.newFile.size / 1024 / 1024", this.newFile.size / 1024 / 1024);
            this.isLt5MYS = this.newFile.size / 1024 / 1024 < 5;
            if (!this.isLt5MYS) {
              this.loadingImg = false;
              this.loadingImg1 = false;
              this.$message.warning(this.$t("Posts.b0c@upload_pictures_can"));
            }
            resolve();
          };
        });
      },
      // 利用canvas压缩图片，根据画布大小和图像质量压缩
      canvasDataURL(obj, fileType) {
        return new Promise(async (resolve, reject) => {
          let that = this;
          var img = new Image();
          img.src = obj.base64;
          img.onload = function () {
            // 获取原始尺寸
            var canvasWidth = this.width;
            var canvasHeight = this.height;
            // 生成canvas
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            // canvas对图片的尺寸
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            // 清除画布
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            // 图片压缩
            ctx.drawImage(this, 0, 0, canvasWidth, canvasHeight);
            // 图像质量,值越小，所绘制出的图像越模糊
            var quality = 0.8;
            var base64String = canvas.toDataURL(fileType, quality);
            // 通过base64获取二进制文件
            that.newFile = that.getBlobByBase64(base64String, fileType);
            resolve();
          };
        });
      },
      // Base64转文件流
      getBlobByBase64(base64String, fileType) {
        var arr = base64String.split(",");
        // mine = arr[0].match(/:(.*?);/)[1],
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {
          type: fileType,
        });
      },

      // 删除已上传的图片
      unshiftMedia(index) {
        this.addPostObj.media.splice(index, 1);
      },

      // friendsInfoList页面上的@friends
      chooseOneFriend(user) {
        if (this.addPostObj.friends.includes(user.user_id)) return;
        this.addPostObj.friends.push(user.user_id);
        this.friendsInfoList.push(user);
      },
      // addressList
      googleNearbyFunc() {
        this.addressLoading = "loading";
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.$http
                .googleNearby({
                  longitude: position.coords.longitude,
                  latitude: position.coords.latitude,
                  pagetoken: this.nextAddressToken ? this.nextAddressToken : "",
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.AddressList = [...this.AddressList, ...res.data.name];
                    if (!res.data.next_page_token) {
                      this.addressDis = true;
                    }
                    this.nextAddressToken = res.data.next_page_token;
                    this.addressLoading = "success";
                  } else {
                    this.addressLoading = "error";
                  }
                });
            },
            (error) => {
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  this.$message.warning(
                    this.$t("Posts.8c9@you_reject_the_reque")
                  );
                  break;
                case error.POSITION_UNAVAILABLE:
                  this.$message.warning(
                    this.$t("Posts.64b@location_information")
                  );
                  break;
                case error.TIMEOUT:
                  this.$message.warning(
                    this.$t("Posts.745@request_for_your_loc")
                  );
                  break;
                case error.UNKNOWN_ERROR:
                  this.$message.warning(this.$t("Posts.39c@unknown_mistake"));
                  break;
              }
            }
          );
        } else {
          this.$message.warning(this.$t("Posts.731@your_browser_does_no"));
        }
      },

      chooseAddress(address) {
        this.addPostObj.location = address.name;
        this.addPostObj.longitude = address.location.longitude;
        this.addPostObj.latitude = address.location.latitude;
      },

      // // 获取个人信息
      getUserInfo() {
        this.$http.getUserInfo().then((res) => {
          if (res.status == 200) {
            this.userInfoList = res.data;
            this.avatar = res.data.user_info.avatar;
          }
        });
      },

      // 新增帖子
      addNewPost() {
        this.isPostClose = true;
        // 分享--share_type为分享类型
        this.addPostObj.share_type = 61;
        this.addPostObj.share_id = this.shareId;
        // this.addPostObj.share_id = this.$route.query.channel_id;
        this.addPostObj.content = this.addPostObj.content
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/‘/g, "&#039;")
        // .replace(/(\r\n|\n|\r)/gm, "<br />");

        // console.log(myString);
        // console.log("this.addPostObj2222", this.addPostObj);
        //   this.$emit("addPost", this.addPostObj, this.permission);
        this.$http
          .createPost({
            type: 1, // 个人发帖
            permission: this.permission,
            ...this.addPostObj,
          })
          .then((res) => {
            if (res.status == 200) {
              // this.getUserPostList();
              this.sharePostInfomation.share_total++
            }
          });
        this.clearForm();
        this.$bvModal.hide("modal1");
      },

      // 分享
      getShareInfo(id) {
        this.$http
          .getPostInfo({
            user_post_id: id,
          })
          .then((res) => {
            this.sharePostInfomation = res.data;
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  // 地址弹框
  ::v-deep .dropdown-menu {
    width: 350px;
    height: 300px;
    overflow: auto;
  }

  .my_test {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .width_120 {
    /* width: 120px; */
    display: flex !important;
  }

  .delFa {
    position: relative;

    .del {
      position: absolute;
      width: 15px;
      height: 15px;
      top: -10px;
      right: -5px;
    }
  }

  #modal1 {
    textarea {
      box-sizing: border-box;
      color: transparent;
      background-color: transparent;
      caret-color: #000;
      padding: 0;

      &:focus {
        outline: none;
      }
    }

    textarea::-webkit-input-placeholder {
      line-height: 56px;
    }

    textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      line-height: 56px;
    }

    textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      line-height: 56px;
    }

    textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      line-height: 56px;
    }
  }

  ::v-deep #modal1 {

    .modal-content,
    .modal-header {
      width: 660px !important;
      border-bottom: 0;
    }

    .modal-body {
      padding: 0;
      box-sizing: border-box;
    }

    .content_post {
      padding: 15px 30px 0;
      border-top: 1px solid #f1f1f1;
    }

    hr {
      border-color: #f1f1f1;
    }

    .option_container {
      padding: 0 30px;
    }

    .el-dropdown {
      color: #50B5FF;
      cursor: pointer;
    }

    .add_new_post {
      border-radius: 0;
      height: 50px;
      font-size: 18px;
      margin-top: 20px;
    }

    .drag_container {
      width: 100%;
      padding-left: 20px;

      .drag_content {
        height: 160px;
        background: #f6f6f6;
      }

      .el-upload-dragger {
        width: 500px;
        border: 0;
        height: auto;
        overflow: visible;
      }

      .el-upload__text {
        color: #ccc;
        font-size: 14px;
        line-height: 16px;
        width: 146px;
        margin: 0 auto;
      }

      .el-loading-mask {
        background-color: rgba(255, 255, 255, .5);
      }

      .all_image {
        width: 100%;

        .four_img:nth-child(2) {
          margin-right: 30px !important;
        }

        .image_item:nth-child(3n) {
          margin-right: 0 !important;
        }

        .main_img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }

      .common_item {
        position: relative;
        margin-bottom: 10px;

        .close_img {
          width: 20px;
          height: 20px;
          position: absolute;
          right: -5px;
          top: -5px;
        }
      }

      .image_item {
        width: 160px;
        height: 160px;
        margin-right: 10px;
      }

      .four_img {
        width: 160px;
        height: 160px;
        margin-right: 10px;
      }

      .one_img {
        text-align: left;

        .main_img,
        video {
          width: auto;
          height: auto;
          max-width: 500px !important;
          max-height: 500px;
        }
      }
    }

    .location_box {
      display: flex;
      align-items: center;
      border: 1px solid #FFBA68;
      color: #FFBA68;
      padding: 0 6px;
      border-radius: 15px;
      margin-left: 20px;
      width: auto;
      line-height: 26px;

      i {
        margin-right: 3px;
        font-size: 18px;
      }

      .el-icon-close {
        font-size: 16px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .friend_box {
      display: flex;
      align-items: center;
      border: 1px solid #50B5FF;
      color: #50B5FF;
      padding: 0 6px;
      border-radius: 15px;
      width: auto;
      line-height: 24px;
      margin-right: 5px;
      margin-bottom: 5px;

      .el-icon-close {
        font-size: 16px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .modal_friend_list {
      display: flex;
      margin-left: 20px;
      margin-bottom: 10px;
      flex-wrap: wrap;
    }

  }

  // @friends
  .modal_friend_list {
    display: flex;
  }

  ::v-deep .el-upload {
    margin: 0 auto;
    display: flex;
    // justify-content: center;
    justify-content: left;
  }

  ::v-deep .dropdown-toggle::after {
    display: none;
  }

  ::v-deep .btn-secondary {
    padding: 0;
    background-color: #fff !important;
    border-color: #fff !important;
  }

  .user-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
    background: url("/assets/images/public/user.png");
    background-size: 100%;
  }

  .iq-card-body:focus {
    outline: none;
  }

  .share_link {
    border: 1px solid #f1f1f1;
    background-color: #f8f8f8;
    display: flex;

    >.img_box {
      width: 80px;
      height: 80px;

      >img,
      video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .share_content {
      width: calc(100% - 80px);
      padding: 2px 5px;

      >p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }

  ::v-deep .textareaPost {
    height: 60px;

    .el-textarea__inner {
      height: 60px;
      border: none;
      resize: none;
    }

    .el-input__count {
      bottom: -20px;
      background: transparent;
    }
  }

  .post_remark_content {
    width: 100%;
    position: relative;
    margin-left: 20px;

    .detail_content {
      width: calc(100% - 15px);
      overflow: auto;
      height: 100px;
      margin-bottom: 20px;
      color: #333;
      word-break: break-word;

       /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #fff;
      }
    }

    .showLave {
      position: absolute;
      bottom: -19px;
      right: 18px;
      font-size: 12px;
    }
  }

  .postTextarea {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100px;
    border: none;
    resize: none;
    background-color: transparent;
    word-break: break-word;
  }
</style>