<template>
  <section>
    <iq-card body-class="p-0">
      <template v-slot:body>
        <!-- 头像 -->
        <div class="user-post-data d-flex justify-content-between p-3">
          <div @click="
              $router.push({
                path: '/profile',
                query: { user_id: post.user_id },
              })
            " class="d-flex flex-wrap">
            <div class="user-img mr-3">
              <el-avatar fit="cover" :size="60" :src="post.avatar">
                <img src="@/assets/images/public/user.png" />
              </el-avatar>
            </div>
            <div class="media-support-info mt-2">
              <h5 class="mb-0">
                <div class="d-flex align-items-center">
                  <b-link href="javascript:void(0)" style="
                      max-width: 20vh;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      margin-top:5px;;
                    ">{{ post.nick_name }} </b-link> 
                  <el-tag 
                    size="small"
                    v-if="post.job_title&&post.job_title[0]"
                    style="
                      max-width:150px; 
                      margin-left: 15px;
                      color: #FFBA68;
                      border-radius:5px;
                      font-size: 16px;
                      border: 1px solid #FFBA68;
                      overflow: hidden; 
                      text-overflow: ellipsis;" 
                    effect="plain" :title="post.job_title[0][0]">
                    {{post.job_title[0][0]}}
                  </el-tag> 
                </div>
                <p style="font-size: 14px;color:#50b5ff" class="mb-0 text-secondary">
                  {{ post.created_at }}
                </p>
              </h5>
            </div>
          </div>
        </div>

        <!-- content -->
        <section class="pl-3 pr-3">
          <!-- 帖子内容/@/查看图片-->
          <div class="user_post_content" v-if="post.id">
            <div class="d-flex flex-wrap align-items-center">
              <p v-html="postContentFilter(post.content)" ref="postContent" class="m-0"></p>

              <!-- “查看图片” -->
              <ul v-if="post.share_id && post.media.length" class="simple_img d-flex p-0 m-0">
                <!-- v-for="(src, index) in post.media" :key="index" -->
                <li class="pr-3">
                  <i class="ri-image-line"></i>
                  <span @click="overViewPicture(post)">View</span>
                </li>
              </ul>
              <!--@Friends -->
              <ul class="m-0 p-0 d-flex flex-wrap">
                <li v-for="(item, index) in post.friends" :Key="index">
                  <router-link :to="{ path: '/profile', query: { user_id: item.user_id } }" class="mr-2" href="">
                    @{{ item.nick_name }}</router-link>
                </li>
              </ul>
            </div>
          </div>

          <!-- MediaList -->
          <!-- <div class="post_media_list" v-if="!post.share_id">
            <div class="media_item" :class="image.type == 'photo' ? 'img_cover' : ''"
              v-for="(image, index) in post.media" :key="index" @click="getPostDetail(post, image, index)">
              <img v-if="image.type == 'photo'" :src="image.url" />
              <video preload controls v-else :src="image.url"></video>
            </div>
          </div> -->

          <div class="post_media_list" v-if="!post.share_id">
            <div class="media_item"
              :class="{'img_cover':image.type == 'photo','one_img':post.media.length==1,'four_img':post.media.length==4,'common_img':post.media.length!=1&&post.media.length!=4}"
              v-for="(image, index) in post.media" :key="index" @click="getPostDetail(post, image, index)">
              <el-image v-if="image.type == 'photo'" :src="image.url" fit="cover">
                <div slot="error" class="image-slot">
                  <img src="@/assets/images/public/fail.png" />
                </div>
              </el-image>
              <video preload controls v-else :src="image.url"></video>
            </div>
          </div>

          <!-- location -->
          <div class="m-0" v-if="post.location" style="color: #EDAF3C">
            <i class="ri-map-pin-line"></i>{{ post.location }}
          </div>

          <!-- like、comment、share -->
          <ul class="like_comment_share p-0 m-1">
            <li v-if="post.is_user_send">
              <i @click="deletePost(post)" class="ri-delete-bin-line"></i>
            </li>
            <li class="bg_gray" v-if="!post.is_user_send && !post.share_id">
              <i @click="shareStaus(post)" class="ri-share-line"></i>
              <span class="ml-1" @click="checkSharePost(post)">{{ post.share_total }} Share</span>
            </li>
            <li class="bg_gray" :class="post.isShowCommentList ? 'text-primary' : ''">
              <i @click="getFirstCommentList(post)" class="ri-message-3-line"></i>
              <span class="ml-1" @click="getFirstCommentList(post)">{{ post.comment_total }} Comment</span>
            </li>
            <li class="bg_gray" :class="{ 'text-primary': post.user_post_like }">
              <i @click="likePost(post)" class="ri-thumb-up-line"></i>
              <span class="ml-1" @click="checkLikePost(post)">{{ post.like_total }} Likes</span>
            </li>
          </ul>
          <div v-if="likePostShow">
            <likePostDet :id="post.id"></likePostDet>
          </div>
          <div v-if="sharePostShow">
            <sharePostDet :id="post.id"></sharePostDet>
          </div>

          <!-- 顶级评论 -->
          <div v-if="post.isShowCommentList">
            <!-- 大评论框 -->
            <!-- 评论的图片列表 -->
            <ul class="p-0 m-0 media_box">
              <li class="li_pic" v-for="(item, index) in commentMedia" :key="index">
                <img v-if="item.type == 'photo'" :src="item.url" alt="" />
                <video v-else :src="item.url" controls autoplay></video>
                <img class="close_img" src="@/assets/images/add_post_close.png" @click="commentMedia.splice(index, 1)"
                  alt="" style="cursor: pointer" />
              </li>
            </ul>
            <b-form @submit.prevent class="comment-text d-flex align-items-center pb-3">
              <b-form-input type="text" v-model="commentPostContent" class="rounded" />
              <!-- @keyup.enter.native="commentPost(post)" -->
              <div class="comment-attagement d-flex">
                <el-upload class="avatar-uploader" action="no" :data="{ uploadIndex: 1 }" :http-request="imgUpload"
                  :show-file-list="false" :before-upload="beforeAvatarUpload">
                  <i class="ri-camera-line mr-2"></i>
                </el-upload>
                <button :disabled="!commentPostContent && !commentMedia.length" class="btn btn-primary"
                  @click="commentPost(post)">
                  {{ $t("Channels.a35@send") }}
                </button>
              </div>
            </b-form>

            <!-- 顶级评论 -->
            <ul class="comment_and_replay p-0">
              <li v-for="postComment in post.firstCommentList" :key="postComment.id">
                <div class="d-flex justify-content-between align-items-top">
                  <div class="user_avatar mr-2">
                    <img :src="postComment.avatar" alt="" />
                  </div>
                  <div class="comment_content">
                    <div class="comment_main d-flex">
                      <span>
                        {{ postComment.nick_name }}
                        <el-tag v-if="postComment.status==2" size="mini">admin</el-tag>
                      </span>：
                      <div class="m-0 d-flex">
                        {{ JSON.parse(postComment.content).content }}
                        <ul v-if="JSON.parse(postComment.content).img.length" class="simple_img d-flex p-0 m-0">
                          <li class="pr-3">
                            <i class="ri-image-line"></i>
                            <span @click="overViewPicture(postComment.content)">View</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="time_icon">
                      <span>{{ postComment.created_at }}</span>
                      <ul class="m-0 p-0 d-flex">
                        <li v-show="postComment.is_user_send" @click="delSelfPostComment(post, postComment)">
                          <i class="ri-delete-bin-line"></i>
                        </li>
                        <li @click="openReplayComment(postComment)">
                          <i class="ri-message-3-line"></i>
                        </li>
                        <li :class="{
                            'text-secondary': !postComment.is_like,
                            'text-primary': postComment.is_like,
                          }" @click="likePostComment(postComment)">
                          <i class="ri-thumb-up-line"></i>
                          <em style="
                              font-style: normal;
                              min-width: 18px;
                              display: inline-block;
                              text-align: right;
                            ">{{ postComment.like_total }}</em>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- 二级评论列表入口---gmc等人共8条回复-->
                <div v-if="postComment.comment_total" class="second_comment">
                  <span class="mr-1">{{ postComment.comment_user_name }}</span>
                  Et
                  <span class="ml-1" @click="secondCommentOpen(postComment)">all {{ postComment.comment_total }}
                    reply</span>
                  <i class="ri-arrow-down-s-fill"></i>
                </div>
              </li>
            </ul>

            <hr />
            <!-- <div
              v-if="post.comment_total > 5 && isShowViewAllComment"
              class="all_comment"
            >
              <span @click="toPostDetail(post)"
                >View all {{ post.comment_total }} comments<i
                  class="ri-arrow-right-s-line ml-1"
                  style="font-size: 18px"
                ></i
              ></span>
            </div> -->
          </div>
        </section>
      </template>
    </iq-card>

    <!-- SecondList -->
    <b-modal :id="`secondCommentListModal${commentListParent.id}`" centered :title="`${secondCommentList.length} Reply`"
      hide-footer scrollable>
      <!-- 父级评论 -->
      <ul class="comment_and_replay p-0 m-0">
        <li>
          <div class="d-flex justify-content-between align-items-top">
            <div class="user_avatar mr-2">
              <img :src="commentListParent.avatar" alt="" />
            </div>
            <div class="comment_content">
              <div class="comment_main d-flex">
                <span>
                  {{ commentListParent.nick_name }}
                  <el-tag v-if="commentListParent.status==2" size="mini">admin</el-tag>
                </span>：
                <div class="m-0 d-flex">
                  {{
                  commentListParent.content &&
                  JSON.parse(commentListParent.content).content
                  }}
                  <ul v-if="
                      commentListParent.content &&
                      JSON.parse(commentListParent.content).img.length
                    " class="simple_img d-flex p-0 m-0">
                    <li class="pr-3">
                      <i class="ri-image-line"></i>
                      <span @click="overViewPicture(commentListParent.content)">View</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="time_icon">
                <span>{{ commentListParent.created_at }}</span>
                <ul class="m-0 p-0">
                  <li @click="commentListParent.isShowReplayForm = true">
                    <i class="ri-message-3-line"></i>
                  </li>
                  <li :class="{
                      'text-secondary': !commentListParent.is_like,
                      'text-primary': commentListParent.is_like,
                    }" @click="likePostComment(commentListParent)">
                    <i class="ri-thumb-up-line"></i>{{ commentListParent.like_total }}
                  </li>
                </ul>
              </div>
              <div v-if="commentListParent.isShowReplayForm" class="replay_white_box">
                <input v-model="commentListParent.replayForm" placeholder="Reply" type="text" />
                <div class="mt-2">
                  <b-button @click="
                      replayModalComment(
                        commentListParent.channel_post_id,
                        commentListParent,
                        commentListParent.replayForm,
                        post
                      )
                    " variant="primary" size="sm">Reply</b-button>
                </div>
              </div>
            </div>
          </div>

          <!-- 二级评论详细内容 -->
          <ul class="p-0" v-infinite-scroll="load" style="height: 300px;" infinite-scroll-disabled="disabled">
            <li v-for="second in secondCommentList" :key="second.id">
              <div class="second_comment d-flex">
                <span class="mr-1">{{ second.nick_name }}</span>
                <p class="m-0" v-if="second.parent != 1">
                  Reply<span class="text-primary ml-1">
                    <!-- {{commentListParent.nick_name}}  -->
                    {{second.parent&&second.parent.nick_name||commentListParent.nick_name}}
                  </span>
                </p>
                :
                <p class="m-0" v-if="
                    second.content &&
                    !JSON.parse(second.content).content &&
                    !JSON.parse(second.content).img
                  ">
                  {{ second.content }}
                </p>

                <div class="m-0 d-flex">
                  {{ second.content && JSON.parse(second.content).content }}
                  <ul v-if="
                      second.content &&
                      JSON.parse(second.content).img &&
                      JSON.parse(second.content).img.length
                    " class="simple_img d-flex p-0 m-0">
                    <li class="pr-3">
                      <i class="ri-image-line"></i>
                      <span @click="overViewPicture(second.content)">View</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="
                  second_time
                  d-flex
                  justify-content-between
                  align-items-center
                ">
                <span>{{ second.created_at }}</span>
                <ul class="m-0 p-0 d-flex second_icon">
                  <li v-if="second.is_user_send" @click="deleteModalComment(second, post)">
                    <i class="ri-delete-bin-line"></i>
                  </li>
                  <li @click="second.isShowReplayForm = true">
                    <i class="ri-message-3-line"></i>
                  </li>
                  <li :class="{
                      'text-secondary': !second.is_like,
                      'text-primary': second.is_like,
                    }" @click="likePostComment(second)">
                    <i class="ri-thumb-up-line"></i>
                    {{ second.like_total }}
                  </li>
                </ul>
              </div>
              <div v-if="second.isShowReplayForm" class="left_padding_white_box">
                <input v-model="second.replayForm" placeholder="Reply" type="text" />
                <div class="mt-2">
                  <b-button @click="
                      replayModalComment(
                        second.channel_post_id,
                        second,
                        second.replayForm,
                        post,
                        true
                      )
                    " variant="primary" size="sm">Reply</b-button>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </b-modal>

    <!-- second Comment input -->
    <b-modal :id="`commentForm${firstCommentObj.id}`" centered :title="`Reply ${firstCommentObj.nick_name}`"
      hide-footer>
      <el-upload class="avatar-uploader" action="no" :data="{ uploadIndex: 2 }" :http-request="imgUpload"
        :show-file-list="false">
        <i class="ri-camera-line mr-2" style="font-size: 30px"></i>
      </el-upload>

      <!-- 评论的图片列表 -->
      <ul class="p-0 m-0 media_box">
        <li class="li_pic" v-for="(item, index) in commentMediaEr" :key="index">
          <img v-if="item.type == 'photo'" :src="item.url" alt="" />
          <video v-else :src="item.url" controls autoplay></video>
          <img class="close_img" src="@/assets/images/add_post_close.png" @click="commentMediaEr.splice(index, 1)"
            alt="" style="cursor: pointer" />
        </li>
      </ul>

      <b-form-textarea id="textarea" placeholder="Enter something..." v-model="replay_textarea" rows="3" max-rows="6">
      </b-form-textarea>

      <b-button @click="replayPostComment(post)" class="mt-2" block variant="primary">Reply</b-button>
    </b-modal>

    <!-- PostDetail -->
    <selfModal v-if="isShowSelfModal" style="width: 100%; height: 100%; position: fixed; top: 0; left: 0"
      @close="closeSelfModal" :postDetail="post" :mediaIndex="mediaIndex" />

    <!-- view-picture -->
    <viewPicture v-if="isShowViewPicture" :mediaListProp="viewPictureImg" @closeViewPhoto="isShowViewPicture = false"
      style="width: 100%; position: fixed; top: 0; left: 0; z-index: 9999" />

    <SharePost ref="sharePost" />
  </section>
</template>
<script>
  import bus from "@/Utils/Bus";
  import selfModal from "./selfModal";
  import viewPicture from "@/components/ComComponents/previewPicture";
  import SharePost from "./SharePost.vue";
  import likePostDet from './likePostDet'
  import sharePostDet from './sharePostDet'
  import { mapGetters } from "vuex";
  export default {
    name: "SocialPost",
    props: {
      friendsList: {
        type: Array,
      },
      post: {
        type: Object,
        default: {},
      },
      pageType: {
        type: String,
        default: "",
      },
      isShowViewAllComment: {
        type: Boolean,
        default: true,
      },
      isPostType: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      selfModal,
      viewPicture,
      SharePost,
      sharePostDet,
      likePostDet
    },

    data() {
      return {
        commentPostContent: "",
        replay: "",
        childCommentList: [],
        isShowReplay: false,

        // share
        friendsInfoList: [],
        sharePostInfomation: {},

        // viewPicture
        isShowViewPicture: false,
        viewPictureImg: "",

        // selfModal
        isShowSelfModal: false,
        postDetail: {},

        // 回复弹框
        firstCommentPage: 0,
        replay_textarea: "",
        // 传递的comment信息
        firstCommentObj: {},
        // firstCommentList: [],
        // 二级评论弹框
        parentcomment: {},
        secondCommentList: [],
        commentListParent: {},
        // compain
        isHasImg: 0,
        compalinInfo: {},
        compalinInfoeUrl: "",
        compalinInfoType: "",
        complainTypeList: ["Fraud", "violence", "Vulgar", "crime", "other"],
        chooseComplainType: 0,

        // friendsList: [],
        // 好友 和 群组
        friendAndGroup: [],
        // 分享好友 or 群  = > 选中
        selectedList: [],
        // 要分享的内容
        shareData: {},

        // 评论图片列表
        commentMedia: [],

        commentMediaEr: [],
        describe_textarea: "",
        page: 1,
        disabled: false,

        likePostShow: false,
        sharePostShow: false
      };
    },
    filters: {
      filterShareType(val) {
        let type;
        switch (val) {
          case 1: {
            type = "Post";
            break;
          }
          case 2: {
            type = "Meeting";
            break;
          }
          case 3: {
            type = "Course";
            break;
          }
          case 4: {
            type = "Event";
            break;
          }
          case 5: {
            // type = "Project"
            type = "Event";
            break;
          }
          case 6: {
            type = "Post";
            break;
          }
          case 7: {
            type = "Program";
            break;
          }
        }
        return `Sorry, this ${type} has been deleted by the author.`;
      },
    },

    computed: {
      // 获取当前用户相关信息
      ...mapGetters({
        dataChat: "ChatRoom/getDataChat",
      }),

      // 为post添加'是否显示顶级评论+顶级评论列表'
      computedPost() {
        this.$set(this.post, "isShowCommentList", false);
        this.$set(this.post, "firstCommentList", []);
      },
    },

    mounted() {
      // 监听键盘按键事件
      let self = this;
      this.$nextTick(function () {
        document.addEventListener("keyup", function (e) {
          if (e.keyCode == 27) {
            self.isShowViewPicture = false;
            self.isShowSelfModal = false;
          }
        });
      });
      // 处理post
      this.$set(this.post, "isShowCommentList", false);
      this.$set(this.post, "firstCommentList", []);
    },

    methods: {
      checkLikePost(post) {
        if (this.likePostShow) {
          this.likePostShow = false
        } else {
          this.likePostShow = true
          this.sharePostShow = false
          this.$set(post, 'isShowCommentList', false)
          this.$set(this.post, "firstCommentList", []);
        }
      },
      checkSharePost(post) {
        if (this.sharePostShow) {
          this.sharePostShow = false
        } else {
          this.sharePostShow = true
          this.likePostShow = false
          this.$set(post, 'isShowCommentList', false)
          this.$set(this.post, "firstCommentList", []);
        }
      },
      // 分享
      shareStaus(post) {
        // console.log(post);
        // bus.$emit("share", post);
        this.$refs.sharePost._getPost(post)
        setTimeout(() => {
          this.$refs.sharePost._getPost(post)
        }, 100);
      },

      beforeAvatarUpload(file) {
        // console.log(file);
        const isJPG = file.type;
        let arrType = isJPG.split("/");
        // console.log(arrType);
        if (arrType[0] != "image") {
          this.$message.warning(this.$t("Channels.816@please_choose_a_pict"));
        }
      },
      // 上传
      imgUpload(params) {
        // console.log(params);
        var formData = new FormData();
        formData.append("file", params.file);
        this.$http.uploadImage(formData).then((res) => {
          if (params.data.uploadIndex == 1) {
            if (res.status == 200 && this.commentMedia.length < 9) {
              this.commentMedia.push({
                type:
                  params.file.type.split("/")[0] == "image" ? "photo" : "video",
                url: res.data[0],
              });
            }
          } else if (params.data.uploadIndex == 2) {
            if (res.status == 200 && this.commentMediaEr.length < 9) {
              this.commentMediaEr.push({
                type:
                  params.file.type.split("/")[0] == "image" ? "photo" : "video",
                url: res.data[0],
              });
            }
          }
        });
      },

      // 帖子内容过滤
      postContentFilter(val) {
        let exp =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        val=val.replace(
          exp,
          "<a href='$1' target='_blank'><i class='ri-link-m'></i>Link</a>"
        );
         let url = window.location.href.split('#')[0]
       let reg=/(^|\s+)#([^!@#$%^&*()=+./,:'"?<>(^|\s+)]+)/g
        val = val.replace(reg, `<a href='${url}#/search-result?keyword=$2'>$1#$2</a>`)
        val = val.replace(/(\r\n|\n|\r)/gm, "<br />")
        return val
      },

      /**
       * --------帖子相关----------
       * */
      // 点赞帖子
      likePost(post) {
        this.$http
          .operationChannelPost({
            type: "like_post",
            channel_post_id: post.id,
          })
          .then((res) => {
            if (res.status == 200 && !post.user_post_like) {
              // 判断是点赞还是取消点赞
              post.like_total++;
            } else if (post.user_post_like) {
              post.like_total--;
            }
            this.$set(post, "user_post_like", !post.user_post_like);
          });
      },

      // // 获取顶级评论列表
      async getFirstCommentList(post) {
        if (!post.isShowCommentList) {
          this.$set(post, "isShowCommentList", true);
          this.sharePostShow = false
          this.likePostShow = false
          let firstChildList = await this.$http.operationChannelPost({
            type: "get_comment",
            channel_post_id: post.id,
            size:50
          });
          // console.log('firstChildList', firstChildList);
          post.firstCommentList = firstChildList.data.data;
        } else {
          this.$set(post, "isShowCommentList", false);
        }
      },

      /**
       * 评论帖子--大评论框
       * parent-回复谁就是谁的id,评论帖子0
       * com_parent:顶级id，评论帖子时不存在
       * */
      commentPost(post) {
        // 评论内容格式化
        let imgTextContent = {};
        imgTextContent.content = this.commentPostContent;
        imgTextContent.img = this.commentMedia;

        this.$http
          .operationChannelPost({
            type: "add_comment",
            channel_post_id: post.id,
            parent: 0,
            content: JSON.stringify(imgTextContent),
          })
          .then((res) => {
            if (res.status == 200) {
              // console.log("post", post);
              post.firstCommentList.unshift(res.data);
              this.commentPostContent = "";
              // this.commentPostContent = ''
              this.commentMedia = [];

              // console.log(post);
              let plus1 = post.comment_total + 1;
              this.$set(post, "comment_total", plus1);
            }
          });
      },

      // 点赞评论
      likePostComment(comment) {
        this.$http
          .operationChannelPost({
            type: "like_comment",
            channel_post_comment_id: comment.id,
          })
          .then((res) => {
            if (res.status == 200) {
              // 判断是点赞还是取消点赞
              comment.like_total++;
            } else {
              comment.like_total--;
            }
            this.$set(comment, "is_like", !comment.is_like);
          });
      },

      // 打开二级评论弹框
      openReplayComment(comment) {
        this.firstCommentObj = comment;
        this.$nextTick(() => {
          this.$bvModal.show(`commentForm${this.firstCommentObj.id}`);
        });
      },

      /**
       * textarea-modal回复评论--回复顶级
       * parent:回复谁，就是谁的id
       * com_parent:回复的评论的id
       * */
      replayPostComment(post) {
        if (!this.replay_textarea.trim() && !this.commentMediaEr.length)
          return this.$message.error(this.$t("Channels.e95@fail_to_comment"));
        // 评论内容格式化
        let imgTextContent = {};
        imgTextContent.content = this.replay_textarea;
        imgTextContent.img = this.commentMediaEr;
        // 顶级评论的信息传递
        let comment = this.firstCommentObj;
        this.$http
          .operationChannelPost({
            // type: this.computedPageType,
            type: "add_comment",
            channel_post_id: post.id,
            parent: comment.id, // 顶级评论的parent为0
            // commet_parent: comment.id,
            content: JSON.stringify(imgTextContent),
          })
          .then((res) => {
            // 评论成功，显示'gmc等人共1条回复'
            // console.log(res);
            if (res.status == 200) {
              comment.comment_user_name = res.data.nick_name;
              comment.comment_total++;
              this.replay_textarea = "";
              this.commentMediaEr = [];
              this.$bvModal.hide(`commentForm${this.firstCommentObj.id}`);
              this.$set(post, "comment_total", post.comment_total + 1);
            }
          });
      },

      // 删除评论
      delSelfPostComment(post, comment) {
        this.$confirm(
          "This operation will delete the comment. Do you want to continue?",
          this.$("Channels.74d@tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {
          // console.log("comment", comment);
          // console.log("post", post);
          this.$http
            .operationChannelPost({
              type: "del_comment",
              channel_post_comment_id: comment.id,
            })
            .then((res) => {
              // 删除顶级列表的某一项
              this.$set(
                post,
                "firstCommentList",
                post.firstCommentList.filter((some) => some.id !== comment.id)
              );
              let subd_n = post.comment_total - comment.comment_total - 1;
              this.$set(post, "comment_total", subd_n);
            });
        });
      },

      /**
       * ----------二级评论列表弹框--------
       * */

      // 打开二级评论列表
      async secondCommentOpen(commentInfo) {
        this.disabled = false
        this.page = 1
        // console.log('commentInfo', commentInfo);
        // 父级评论信息
        this.$set(commentInfo, "isShowReplayForm", false);
        this.$set(commentInfo, "replayForm", "");
        this.$set(commentInfo, "childresComment", []);
        // 设置二级弹框列表的唯一性
        this.commentListParent = commentInfo;

        // 获取二级评论列表
        let res = await this.$http.operationChannelPost({
          type: "get_child_comment",
          channel_post_comment_id: commentInfo.id,
        });
        let { children_comment } = res.data;
        children_comment.data.forEach((item) => {
          this.$set(item, "isShowReplayForm", false);
          this.$set(item, "replayForm", "");
        });
        this.secondCommentList = children_comment.data;
        // console.log("this.secondCommentList", this.secondCommentList);
        // 打开弹框
        this.$nextTick(() => {
          this.$bvModal.show(`secondCommentListModal${commentInfo.id}`);
        });
      },

      // 在弹框上回复评论---“回复”按钮
      replayModalComment(postId, comment, commentContent, post, flag) {
        // 两种情况判断--回复顶级评论&回复二级评论
        // 回复顶级评论：parent为回复的评论的id，comment_parent为顶级评论id（comment.id）
        // console.log('comment', comment);

        // 评论内容格式化
        let imgTextContent = {};
        imgTextContent.content = commentContent;
        // imgTextContent.img = [];
        this.$http
          .operationChannelPost({
            // type: this.computedPageType,
            type: "add_comment",
            channel_post_id: postId,
            parent: comment.id,
            // commet_parent: comment.commet_parent
            //   ? comment.commet_parent
            //   : comment.id,
            content: JSON.stringify(imgTextContent),
          })
          .then((res) => {
            // console.log(res);
            if (res.status == 200) {
              // console.log(res);
              // 给自制二级评论添加两个变量控制回复框的显示和值
              this.$set(res.data, "isShowReplayForm", false);
              this.$set(res.data, "replayForm", "");

              this.secondCommentList.unshift(res.data);

              // 隐藏回复框和清空
              comment.replayForm = "";
              comment.isShowReplayForm = false;
              post.comment_total++;
              if (flag) {
                this.commentListParent.comment_total++;
              } else {
                comment.comment_total++;
              }
            }
          });
      },

      // 滚动加载
      async load() {
        this.page++
        // 获取二级评论列表
        let res = await this.$http.operationChannelPost({
          type: "get_child_comment",
          channel_post_comment_id: this.commentListParent.id,
          page: this.page
        });
        let { children_comment } = res.data;
        children_comment.data.forEach((item) => {
          this.$set(item, "isShowReplayForm", false);
          this.$set(item, "replayForm", "");
        });
        if (children_comment.data.length == 0) {
          this.disabled = true
          return
        }
        this.secondCommentList = [...this.secondCommentList, ...children_comment.data]
      },

      // 删除modal评论
      deleteModalComment(second, post) {
        // console.log(second);
        this.$confirm(
          "This operation will delete the comment. Do you want to continue?",
          this.$("Channels.74d@tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {
          this.$http
            .operationChannelPost({
              type: "del_comment",
              channel_post_comment_id: second.id,
            })
            .then((res) => {
              this.secondCommentList = this.secondCommentList.filter(
                (item) => item.id != second.id
              );
              let subd_n = post.comment_total - 1;
              this.parentcomment.comment_total--;
              this.$set(post, "comment_total", subd_n);
            });
        });
      },

      /**
       * ---------帖子详情--------
       * */

      getPostDetail(post, image, index) {
        if (image.type == "photo") {
          this.isShowSelfModal = true;
          this.mediaIndex = index;
          this.getFirstCommentList(post);
          this.$http
            .operationChannelPost({
              type: "post_info",
              channel_post_id: post.id,
            })
            .then((res) => {
              this.postDetail = res.data;
            });
        }
      },

      // 关掉自制modal
      closeSelfModal() {
        this.isShowSelfModal = false;
      },

      toPostDetail(post) {
        this.$emit("SharePostDetail", post);
      },

      overViewPicture(post) {
        // console.log(post);
        // 判斷是帖子的查看圖片還是評論的
        if (typeof post === "string") {
          // 評論的圖片
          let media = JSON.parse(post).img;
          this.viewPictureImg = media;
        } else {
          // 帖子的圖片
          this.viewPictureImg = post.media;
        }
        this.isShowViewPicture = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .post_media_list {
      .four_img:nth-child(2) {
        margin-right: 10px !important;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .post_media_list {
      .four_img:nth-child(2) {
        margin-right: 160px !important;
      }
    }
  }

  ::v-deep .modal-body {
    margin-bottom: 20px !important;
  }

  .user_post_content {
    >div {

      ul,
      li,
      p {
        display: inline-block;
      }
    }
  }

  .rightTopBtn {
    height: 100px;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100px;
      height: 100px;
      object-fit: cover;
      display: block;
    }

    .typeBtn {
      padding: 0 10px;
      height: 25px;
      background: #dddddd;
      border-radius: 0px 0px 0px 5px;
      font-size: 12px;
      color: #ffffff;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  ::v-deep .post_media_list {
    width: 100%;

    display: flex;
    flex-wrap: wrap;

    .common_img {
      width: 160px;
      height: 160px;
    }

    .el-image {
      width: 100%;
      height: 100%;
      display: block;
    }

    >.media_item {
      overflow: hidden;
      border-radius: 8px;
      margin-top: 5px;
      margin-right: 10px;
      position: relative;

      img,
      video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.img_cover::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
      }

      &.img_cover:hover {
        cursor: pointer;

        &::before {
          opacity: 1;
        }
      }

      >#photo-grid {
        width: 100%;
      }
    }

    .one_img {
      text-align: left;

      img,
      video {
        width: auto;
        height: auto;
        max-width: 100% !important;
        max-height: 500px;
      }
    }

    .four_img {
      width: 160px;
      height: 160px;
    }

    .four_img:nth-child(2) {
      margin-right: 120px;
    }
  }

  // 图片缩略图
  ul.simple_img>li {
    color: #50b5ff;
    cursor: pointer;

    &:hover {
      &>span {
        text-decoration: underline;
      }
    }
  }

  .share_link {
    padding: 15px;
    border: 1px solid #f1f1f1;
    background-color: #f9f9f9;
    border-radius: 5px;
    margin-top: 22px;

    .share_avatar {
      >div {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        >img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      >h3 {
        font-size: 16px;
        padding-left: 10px;
        color: #333;
      }
    }

    .media_box {
      display: flex;
      flex-wrap: wrap;

      >li {
        width: 25%;
        height: 0;
        padding-bottom: 25%;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 5px;
        margin-right: 5px;
        position: relative;

        >img,
        video {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.img_box::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-color: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 99;
        }

        &.img_box:hover {
          cursor: pointer;

          &::before {
            opacity: 1;
          }
        }
      }
    }

    >.share_icon_list {
      >p {
        color: #666;
        font-size: 14px;
      }

      >ul>li {
        color: #666;
        cursor: pointer;
        margin-left: 1rem;

        >i {
          font-size: 14px;
          padding: 5px;
          margin-right: 5px;

          &:hover {
            background-color: rgb(204, 229, 245);
            border-radius: 50%;
            color: #50b5ff;
          }

          &:hover+span {
            color: #50b5ff;
          }
        }
      }
    }
  }

  .original_post_del {
    padding: 15px;
    border: 1px solid #f1f1f1;
    background-color: #f8f8f8;
  }

  // like_comment_share
  .like_comment_share {
    display: flex;
    justify-content: space-evenly;
    color: #999;

    li {
      display: flex;
      align-items: center;
      width: 33.3333%;
      margin: 5px 5px 5px 0;

      >i {
        cursor: pointer;
        font-size: 18px;
        padding: 0 5px;

        &:hover {
          background-color: rgb(204, 229, 245);
          border-radius: 50%;
          color: #50b5ff;
        }

        &:hover+span {
          color: #50b5ff;
        }
      }

      &:nth-child(2) {
        justify-content: center;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }

    .bg_gray {
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        padding: 0px 8px;
        border-radius: 12px;
        cursor: pointer;
        line-height: 26px;

        &:hover {
          background-color: #F6F6F6;
        }
      }
    }
  }

  .comment_and_replay>li {
    margin-bottom: 10px;

    .user_avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;

      >img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .comment_content {
      width: calc(100% - 35px);

      .comment_main {
        >span:first-child {
          color: #50b5ff;
          cursor: pointer;
        }
      }

      .time_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 12px;
          color: #999;
        }

        >ul {
          display: flex;
          font-size: 18px;

          &:hover {
            &>li:first-child {
              opacity: 1;
            }
          }

          >li {
            margin-left: 10px;
            padding: 0 5px;
            border-radius: 50%;
            cursor: pointer;

            &:first-child {
              opacity: 0;
            }

            >i {
              padding: 5px;
              border-radius: 50%;

              &:hover {
                background-color: rgb(204, 229, 245);
                color: #50b5ff;
              }
            }
          }
        }
      }

      .replay_white_box {
        background: #f0f0f0;
        padding: 10px;
        justify-content: flex-end;

        >input {
          width: 100%;
        }

        >div {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .second_comment {
      display: flex;
      padding-left: 55px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 3px;
        height: 60%;
        background-color: #f0f0f0;
        position: absolute;
        top: 50%;
        left: 43px;
        transform: translateY(-50%);
      }

      >span {
        color: #50b5ff;
        cursor: pointer;
      }
    }

    .second_time {
      padding-left: 55px;

      // li hover是显示icon
      &:hover .second_icon {
        opacity: 1;
      }

      span {
        font-size: 12px;
      }

      >ul {
        display: flex;
        opacity: 0;
        font-size: 18px;

        >li {
          margin-left: 10px;
          padding: 0 5px;
          border-radius: 50%;
          cursor: pointer;

          >i {
            padding: 5px;
            border-radius: 50%;

            &:hover {
              background-color: rgb(204, 229, 245);
              color: #50b5ff;
              // opacity: 1;
            }
          }
        }
      }
    }

    .left_padding_white_box {
      background: #f0f0f0;
      padding: 10px;
      margin-left: 55px;
      justify-content: flex-end;

      >input {
        width: 100%;
      }

      >div {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .all_comment {
    text-align: center;
    padding-bottom: 15px;
    cursor: pointer;

    &:hover>span {
      color: #50b5ff;
    }
  }

  #photo-grid {
    width: 100%;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    video {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .ad-flag {
    /* display: block; */
    padding: 0 5px;
    height: 25px;
    border: 1px solid #ddd;
    text-align: center;
    line-height: 25px;
    color: #999;
    border-radius: 6px;
  }

  .complain_box {
    >h3 {
      color: #333;
      margin: 10px 0;
      font-size: 14px;
    }

    >.complain_content {
      display: flex;
      background-color: #f4f5f6;
      border: 1px solid #f1f1f1;

      >.img_box {
        width: 80px;
        height: 80px;
        overflow: hidden;

        >img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .share_content {
        padding: 2px 5px;

        >p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }

    >.radia_choose {
      display: flex;
      flex-wrap: wrap;

      >li {
        width: calc(33.3333% - 14px);
        height: 45px;
        background-color: #f4f5f6;
        color: #333;
        margin-right: 14px;
        margin-top: 16px;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
      }
    }
  }

  .media_box {
    display: flex;
    flex-wrap: wrap;

    >.li_pic {
      width: 69px;
      height: 69px;
      margin-right: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
      overflow: hidden;
      border-radius: 8px;
      position: relative;

      >img,
      >video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; //图片自适应的样式
      }

      .close_img {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }

  .violation_post {
    display: flex;

    p {
      width: 100%;
      padding: 13px;
      background: #fff5f4;
      border-radius: 5px;
      color: #ff9b8a;
    }
  }

  .user_post_type {
    margin-left: 8px;
    font-size: 14px;
    color: #999999;

    span {
      margin-left: 3px;
      color: #333;
    }
  }
</style>